.badgeWrapper {
    height: 40px;
    width: 30%;
    cursor: pointer;
    min-width: 120px;
    color: #4558FF;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    background-color: #FFFFFF;
    margin-bottom: 20px;
}
