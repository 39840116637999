.wrapper {
    background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 56.25%, #CFCFCF 100%);
}

.errorBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 78px;
    text-align: center;
    color: #0D1C33;
    margin-bottom: 43px;
    margin-top: 40px;
}

.errorIconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}
.errorIconWrapper > img {
    width: initial;
    max-width: 100%;
    height: auto;
}

.errorMessage {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 49px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #0D1C33;
    margin: 40px 0 25px 0;
    max-width: 1600px;
}

.goHomeBtnWrapper {
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
}

.goHomeBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    height: 78px;
    width: 395px;
    max-width: 100%;
    background: linear-gradient(90deg, #05B6CA -10.64%, #2A6D88 51.94%, #048998 116.51%);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
    border-radius: 24px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 100%;
    text-align: center;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    cursor: pointer;
}

@media (max-width: 500px) {
    .errorMessage {
        font-size: 28px;
        line-height: 38px;
    }
}

@media (max-width: 400px) {
    .goHomeBtn {
        width: 100%;
    }
}
