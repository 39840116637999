
@font-face {
  font-family: 'Montserrat';
  src: url("./assets/fonts/Monsterrat/Montserratarm-Black.otf") format('opentype');
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #4558FF;
  background-repeat: no-repeat;
  height: 100%;
  min-height: 100vh;
  max-height: max-content;
  display: flex;
  align-content: center;
  justify-content: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: 'Montserrat', sans-serif;
}

h1, h2, h3, div, span, input, label, button {
  font-family: 'Montserrat', sans-serif;
}


