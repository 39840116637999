.wrapper {
    background-color: #4558FF;
}

.paymentPreviewWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 24px;

    /*width: 588.86px;*/
    width: 400px;
    max-width: 100%;

    background: #FFFFFF;
    border-radius: 12px;
}

.success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    row-gap: 16px;
}

.successMessage {
    font-family: "Barlow", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #121212;
    align-self: stretch;
}

.dashedLine {
    width: 60%;
    height: 40px;
    border-bottom: 1px dashed #DCDEE0;
}

.solidLine {
    width: 60%;
    height: 40px;
    border-bottom: 1px solid #DCDEE0;
}

.paymentDetails {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    row-gap: 14px;
    margin-top: 42px;
}
.paymentDetails > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.paymentDetails > div > li {
    list-style: none;
    font-family: "Barlow", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #707070;
}
.paymentDetails > div > span {
    font-family: "Barlow", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #121212;
}

.receiptBtn {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    width: 100%;
    border: 1px solid #DEDEDE;
    background-color: #FFFFFF;
    border-radius: 12px;
    font-family: "Barlow", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #3D3D3D;
}
