.btnWrapper {
    padding: 16px 8px;
}

.submitBtn {
    text-align: center;
    height: 48px;
    border: 0 none;
    font-size: 16px;
    padding-left: 20px;
    padding-right: 20px;
    -webkit-appearance: none;
    white-space: nowrap;
    background-image: none;
    cursor: pointer;
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
    color: #ffffff;
    border-radius: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 12px;
}

.iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 400px) {
    .btnWrapper {
        width: 100%;
    }
    .submitBtn {
        width: 100%;
    }
}

.submitBtn:disabled {
    cursor: not-allowed;
}
