.paymentFormWrapper {
  width: 100%;
  height: max-content;
  max-height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 60px 25px 60px 60px;
}

.formWrapper {
  display: flex;
  width: 100%;
}

.formBody {
  width: 100%;
  min-width: 250px;
  display: flex;
  flex-direction: column;
}

.formSections {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.sectionPersonalInfo {
  width: 30%;
  display: flex;
  flex-direction: column;
  padding-bottom: 26px;
}


.titleWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
}

.titleWrapper > h3 {
  max-width: 100%;
  margin: 0;
  font-family: "Barlow", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  text-align: center;
  color: #343a40;
}

.titleWrapper > h3 > mark {
  font-weight: 800;
  background: none;
  color: #343a40;
  padding: 0 4px;
}

.titleWrapper > li {
  font-size: 16px;
  color: #343a40;
  list-style: none;
  padding: 8px 6px;
}
.titleWrapper > li > mark {
  background: none;
  color: #343a40;
  font-weight: 800;
}


.formInput {
  box-sizing: border-box;
  height: 33px;
  background-color: #4558FF;
  border: 1px solid #EDEDED;
  width: 100%;
  max-width: 400px;
  padding: 14px 16px;
  color: #FFFFFF;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.btnWrapper {
  margin-top: 32px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.currencyWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  column-gap: 10px;
}

.currencyTitle {
  opacity: 85%;
}

.submitBtn {
  color: #4558FF;
  background: #ffffff;
  border-radius: 100px;

  height: 50px;
  min-width: 180px;
  width: 280px;

  font-family: "Barlow", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 17px;
  letter-spacing: 0.5px;

  display: flex;
  align-items: center;
  text-align: center;
}
.paymentBannerWrapper {
  width: 100%;
  height: min-content;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.paymentBanner {
  width: 100%;
  height: auto;
}

.errorMessage {
  color: #ee3333;
  padding-top: 8px;
  margin: 0;
}

.helpMessage {
  color: #E9D502;
  padding-top: 8px;
  margin: 0;
}

.badgesWrapper {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;
  width: 60%;
  position: relative;
}


@media (max-width: 730px) {
  .submitBtn {
    width: 220px;
  }
}
@media (max-width: 800px) {
 .formSections {
   flex-direction: column-reverse;
 }
  .formBody {
    width: 100%;
  }
  .badgesWrapper {
    column-gap: 14px;
    row-gap: 14px;
    padding: 0;
    width: 100%;
    justify-content: space-between;
  }
  .sectionPersonalInfo {
    width: 100%;
    align-items: center;
    padding-bottom: 0;
  }
}

@media (max-width: 540px) {
  .formBody {
    margin-top: 20px;
  }
}
