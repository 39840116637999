.wrapper {
    margin-top: 27px;
    width: 100%;
}

.inputWrapper {
    width: 100%;
}

.input {
    /*color: #6c7677;*/
    /*background-color: #ffffff;*/
    /*height: 55px;*/
    /*width: 100%;*/
    /*border-radius: 12px;*/
    /*border: 0 none ;*/
    /*padding: 0 8px;*/
    /*font-size: 15px;*/
    /*line-height: 1.33;*/
    /*box-sizing: border-box;*/
}

.input::placeholder {
    color:#FFFFFF;
    opacity: 0.5;
}

input:focus { border-color: black !important }
.inputError { border: 1px solid red!important }

.label {
    color: #FFFFFF;
    padding-bottom: 7px;
    display: flex;
}

.errorMassage {
    color: #ee3333;
    padding-top: 8px;
    margin: 0;
}



.radioButton {
    display: flex;
    margin: 0;
}

.radioButtonLabel {
    margin: 0;
    padding: 0;
    color: #060707;
}
