.customAmountInput {
    background-color: #FFFFFF;
    height: 40px;
    width: 30%;
    min-width: 120px;
    border-radius: 100px;
    border: none;
    padding: 0 15px;
    font-weight: 500;
    font-size: 12px;
    box-sizing: border-box;
    outline: none;
    margin-bottom: 20px;
}

.customAmountInput::placeholder {
    color: #4558FF;
    text-align: center;
}
.customAmountInput:focus::placeholder {
    color: #FFFFFF;
}

.customAmountInput:focus {
    background-color: #9A5AED;
    color: #FFFFFF;
}


@media (max-width: 550px) {
    .customAmountInput {
        font-size: 8px;
    }
}
